import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageWrapper from '../components/page-wrapper'
import SEO from '../components/service-components/seo'
import { translate } from 'react-i18next'

const IndexPage = ({ data, t }) => (
  <Layout>
    <SEO title="Contacts" keywords={[`gatsby`, `application`, `react`]}/>
    <PageWrapper>
      <div className="container">
        <div className="row">
          <img src={data.site.siteMetadata.avatarUrl}
            className="rounded-circle mx-auto d-block" alt="Avatar"/>
        </div>
        <div className="row mt-3 justify-content-center">
          <div className="col-auto">
            <p className="text-center">
              {
                t('contacts-text').split('\n').map(t =>
                  (<span key={t}>{t}<br /></span>)
                )}
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto">
            <ul className="social-network social-circle">
              <li>
                <a target="_blank" href={data.site.siteMetadata.facebook}
                  className="icoFacebook" title="Facebook">
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li><a target="_blank" href={data.site.siteMetadata.twitter}
                className="icoTwitter" title="Twitter">
                <i className="fa fa-twitter"/>
              </a>
              </li>
              <li>
                <a target="_blank" href={data.site.siteMetadata.linkedIn}
                  className="icoLinkedin" title="Linkedin">
                  <i className="fa fa-linkedin"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto">
            <ul className="social-network social-circle">
              <li>
                <a target="_blank" href={data.site.siteMetadata.instagram}
                  className="icoInstagram" title="Instagram">
                  <i className="fa fa-instagram"/>
                </a>
              </li>
              <li><a href={`mailto:${ data.site.siteMetadata.email }`}
                className="icoMail" title="Mail">
                <i className="fa fa-envelope"/>
              </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PageWrapper>
  </Layout>
)

export default translate()(IndexPage)

export const query = graphql`
    query {
        site {
            siteMetadata {
                avatarUrl,
                email,
                instagram,
                facebook,
                linkedIn,
                twitter
            }
        }
    }
`
